import { Row, Col, Container } from "react-bootstrap";
import React from "react";


const HeaderBlock = ({ data, classes }) => {

  // console.log(data);

  return (
    <Container fluid className={classes + " hero-block "}>
      <Row className="align-items-end header-row">
        <Col data-aos={"fade-up"} xs={12} md={12} lg={12} className="header-wrapper">
          <h2 className="sub-header">{data.subHeader}</h2>
          <h1 className="header">{data.header}</h1>

        </Col>
      </Row>
    </Container>
  );
};

export default HeaderBlock;
