import { Row, Col, Container } from "react-bootstrap";
import React from "react";
import Markdown from 'markdown-to-jsx';


const ParagraphBlock = ({ data, classes }) => {

  // console.log(data);

  return (
    <Container fluid className={classes} style={{paddingTop: '150px'}}>
      <Row className="align-items-end header-row">
        <Col data-aos={"fade-up"} xs={12} md={12} lg={12} className="header-wrapper">
          <Markdown>
            {data.body}
          </Markdown>

        </Col>
      </Row>
    </Container>
  );
};

export default ParagraphBlock;
