import { useState,useEffect } from "react";

const Helpers = {
    formatDate: function(dateString){
        const options = {  year: 'numeric', month: 'long' }
        return new Date(dateString).toLocaleDateString('en-US', options)
    },
    trimString: function(previewString, length=140){
        if (previewString.length > length) {
            var trimmedString = previewString.substring(0, length);
            trimmedString = trimmedString + "...";
        } else {
            return previewString;
        }
        return trimmedString;

    },
    // size = 'thumbnail', 'small', 'medium', 'large', 'full'
    selectImageSize: function(featuredImage, size) {
        var formats = featuredImage.formats;
        if(formats !== undefined && formats[size] !== undefined ){
             return formats[size].url
        } else {
            return featuredImage.url
        }
    },
    selectImageSizeObject: function(featuredImage, size) {
        var formats = featuredImage.formats;
        if(formats !== undefined ){
             return formats[size]
        } else {
            return featuredImage
        }
    },
    generateKey: function(pre) {
        return `${ pre }_${ new Date().getTime() }`;
    },
    useWindowSize: function() {
        // Initialize state with undefined width/height so server and client renders match
        // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
        const [windowSize, setWindowSize] = useState({
            width: undefined,
            // height: undefined,
          });
          useEffect(() => {
            // Handler to call on window resize
            function handleResize() {
              // Set window width/height to state
              setWindowSize({
                width: window.innerWidth,
                // height: window.innerHeight,
              });
            }
            // Add event listener
            window.addEventListener("resize", handleResize);
            // Call handler right away so state gets updated with initial window size
            handleResize();
            // Remove event listener on cleanup
            return () => window.removeEventListener("resize", handleResize);
          }, []); // Empty array ensures that effect is only run on mount
          return windowSize;
    },
    getStrapiMedia: function (url) {
        if (url == null) {
            return null;
        }

        // Return the full URL if the media is hosted on an external provider
        if (url.startsWith("http") || url.startsWith("//")) {
            return url;
        }

        // Otherwise prepend the URL path with the Strapi URL
        return `${process.env.REACT_APP_STRAPI_API_URL || 'http://localhost:1337'}${url}`;
    }
}

export default Helpers;