import React from "react";
import GlobalHeroBlock from "./PageBlocks/HeroBlocks/GlobalHeroBlock";
import HomeHeroBlock from "./PageBlocks/HeroBlocks/HomeHeroBlock";
import TextWithFactsBlock from "./PageBlocks/HeroBlocks/TextWithFactsBlock";
import ImageWithListBlock from "./PageBlocks/HeroBlocks/ImageWithListBlock";
import HeaderBlock from "./PageBlocks/HeroBlocks/HeaderBlock";
import CompanyBlock from "./PageBlocks/HeroBlocks/CompanyBlock";
import HeaderButtonBlock from "./PageBlocks/HeroBlocks/HeaderButtonBlock";
import MapBlock from "./PageBlocks/ContentBlocks/MapBlock";
import CareersHeroBlock from "./PageBlocks/HeroBlocks/CareersHeroBlock";
import ListColumnBlock from "./PageBlocks/HeroBlocks/ListColumnBlock";
import QuoteBlock from "./PageBlocks/HeroBlocks/QuoteBlock";
import ParagraphBlock from "./PageBlocks/ContentBlocks/ParagraphBlock";
import ImageWithFactsBlock from "./PageBlocks/HeroBlocks/ImageWithFactsBlock";
// import GlobalHeroBlock from './PageBlocks/Hero/GlobalHeroBlock';
const blockComponents = {
 // "hero-block.global-hero-block": GlobalHeroBlock, //Testing block, uneeded
   "hero-block.home-hero-block" : HomeHeroBlock, //Home Page Hero
   "hero-block.careers-hero-block": CareersHeroBlock, //Careers Page Hero
   "content-block.company-block": CompanyBlock, //List of Logos
   "content-block.header-block": HeaderBlock, //Blue Header Block
   "content-block.header-button-block": HeaderButtonBlock, //Career link on home page
   "content-block.image-with-list": ImageWithListBlock, //Home page image with list block
   "content-block.list-column-block": ListColumnBlock, //Careers Benefits Block
   "content-block.quote-block": QuoteBlock, //Testimonial on careers
   "content-block.paragraph-block": ParagraphBlock, //Testimonial on careers
   "content-block.text-with-facts": TextWithFactsBlock, //second block on home page + Careers
   "content-block.map-block": MapBlock, //second block on home page + Careers
   "content-block.image-with-facts": ImageWithFactsBlock,
};

const Block = ({ blockData, maps }) => {
    // Prepare the component
    var blockName = blockData.__component;
    const BlockComponent = blockComponents[blockName];

    if (!BlockComponent) {
      return null;
    }
    var afterPeriod = blockName.substr(blockName.indexOf(".") + 1);

    // Display the section
    return <BlockComponent classes={"content-block " + afterPeriod}   data={blockData} maps={maps} />;
  };
  // Display the list of sections
  const Blocks = ({ pageData, maps }) => {

    return (
      <div className="page-wrapper flex flex-col">
        {pageData !== 0 ? <>
          {pageData.map((block) => (
            <>
          <Block
          data-aos={"fade-up"}
            blockData={block}
            key={`${block.__component}${block.id}`}
            maps={block.__component === 'content-block.map-block' ? maps : false}
          />
          </>
        ))}</> : ""}
      </div>
    );
  };

  export default Blocks;
