import { Row, Col, Container } from "react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";


const HeaderButtonBlock = ({ data, classes }) => {

  // console.log(data);

  return (
    <Container fluid className={classes + " hero-block "}>
      <Row className="">
        <Col data-aos={"fade-up"} xs={12} md={12} lg={12} className="header-wrapper">
          <h1 className="sub-header">{data.subHeader}</h1>
          <h1 className="header">{data.header}</h1>
            
            <Link className="careers-link" to={data.buttonLink}>
              <button className="button">{data.buttonText}</button>
              </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default HeaderButtonBlock;
