import React, { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import network from "./utils/Network";
import DynamicRouter from "./utils/DynamicRouter";
import { ReactComponent as Logo } from './assets/logo-solo.svg';


const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          backgroundColor: "#FFFFFF",
          width: "100%",
          height: "100%",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          zIndex: 10000,
        }}
      >
        <Logo style={{ fill: '#100EA0', height: '100px', paddingRight: '3rem' }} />
        <Oval
  height={80}
  width={80}
  color="#100EA0"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  ariaLabel='oval-loading'
  secondaryColor="#100EA0"
  strokeWidth={2}
  strokeWidthSecondary={2}

/>
      </div>
    )
  );
};
function App() {
  const [appData, setAppData] = useState(null);


  useEffect(() => {


    trackPromise(
      axios.all([
        axios.get(network.apiBase + "pages?pagination[limit]=50&populate=deep"),
        axios.get(network.apiBase + "map-datas?pagination[limit]=50"),
        axios.get(network.apiBase + "footer?populate=page_links"),
        axios.get(network.apiBase + "main-menus?populate=deep"),
        axios.get(network.apiBase + "careers?pagination[limit]=50&populate=*")
      ])
      .then(
        axios.spread(function (pages, mapData, footer, menus, careers){
        setAppData({
          "pages": pages.data.data,
          "maps": mapData.data.data,
          "footer": footer.data.data,
          "menus": menus.data.data,
          "careers": careers.data.data
        })
        
       })).catch(error => {
        console.log("error on main data pull:");
        console.log(error);
        // setAppData(null);
      })
    );
    AOS.init({
      duration: 600
    });

    // AOS.refresh();
  }, []);
  return (
    <div className="app">
      <LoadingIndicator />
      {appData && <DynamicRouter pages={appData.pages} menus={appData.menus} footer={appData.footer} maps={appData.maps} careers={appData.careers}/>}
    </div>
  );
}

export default App;
