import { Row, Col, Container } from "react-bootstrap";
import React from "react";


const ImageWithListBlock = ({ data, classes }) => {

  const renderList = ()=> {
    const listItems = data.listItems;
    const list = listItems.map(listItem => {
      return (
        <Col data-aos={"fade-up"} xs={12} md={6} lg={6} className="list-col">
          <p className="list-item">{listItem.title}</p>
        </Col>

        );
      }
    )
    return list;
  };

  return (
    <Container fluid className={classes + " hero-block "}>
      <Row>
        <Col data-aos={"fade-up"} xs={12} md={5} lg={5} className="image-col">
          <div className="image-container" style={{ backgroundImage: `url("${process.env.REACT_APP_STRAPI_API_URL}${data.image?.data?.attributes?.url}")` }}></div>
        </Col>
        <Col data-aos={"fade-up"} xs={12} md={7} lg={7} className="info-container">

          <h2 className="header">{data.header}</h2>

          <Row className="list-container">
            {renderList()}
          </Row>

        </Col>
      </Row>
    </Container>
  );
};

export default ImageWithListBlock;
