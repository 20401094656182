import { Row, Col, Container } from "react-bootstrap";
import React from "react";

const TextWithFactsBlock = ({ data, classes }) => {
  return (
    <Container fluid className={classes + " hero-block"}>
      <Row className="header-container">
        <Col data-aos={"fade-up"} xs={12} md={12} lg={7} xl={6} className="header-column">
          <h2 className="header mb-5">{data.header}</h2>
        </Col>
      </Row>
      <Row>
        <Col data-aos={"fade-up"} xs={12} md={12} lg={6} className="body-column">
          <p>{data.body}</p>
        </Col>
        <Col data-aos={"fade-up"} xs={12} md={12} lg={6} className="facts-column">
          <Col data-aos={"fade-up"} xs={10} md={4} lg={4} className="fact">
            <h1 className="fact-header">{data.facts[0].stat}</h1>
            <p className="fact-text">{data.facts[0].subText}</p>
          </Col>
          <Col data-aos={"fade-up"} xs={10} md={6} lg={6} className="fact">
            <h1 className="fact-header">{data.facts[1].stat}</h1>
            <p className="fact-text">{data.facts[1].subText}</p>
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

export default TextWithFactsBlock;
